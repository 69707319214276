.wrapper {
    display: flex;
    flex-direction: column;
}

.wrapper h2 {
    font-weight: 500;
    margin-bottom: 4px;
}

.wrapper p {

}